.ant-btn:not(.ant-btn-dashed) {
    &.ant-btn, &.ant-input-search-button {
        &:not(:disabled)&:hover, &:active {
            border: 1px solid @primary-color;
        }  
        &:not(:disabled)&:focus {
            border: 1px solid #FABE78;
        }
    }
    &.ant-btn-icon-only:not(.ant-input-search-button)  {
        border: none !important;
        &:not(:disabled)&:hover, &:active {
            color: @primary-color;
        }  
    }
    &.ant-btn-primary {
        border:none;
        outline: none; 
        transition-duration: 0.0s;
        &:not(:disabled) {
            color:@black;
            &:hover, &:active {
                color:@black;
                background: @main-yellow;
                border:none;
            }
            &:focus {
                background: #FFD200;
                border: none;
            }         
        }   
    }
    &.ant-btn-background-ghost {
        border:none;
        outline: none; 
        &:not(:disabled) {
            color:@black;
            border: 1px solid rgba(0, 0, 0, 0.06); 
            &:hover, &:active {
                border: 1px solid @primary-color;
            }
            &:focus {
                border: 1px solid #FABE78;
            }         
        }         
    }
}