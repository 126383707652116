
.ant-dropdown-menu  {
    border: 1px solid @disable-yellow;
}

ul li {
    &.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
        color: @main-yellow; 
        &:hover {
            background: @light-yellow;
        }
    }
    
}

.anticon.customIcon svg {
    width:20px;
    height: 20px;
    & .tag {     
        fill:@main-yellow;
    }
}

.ant-table-cell {
    span.ant-upload-list-item-name {
        font-size: 10px;
    }
}
div.ant-upload-list-item-info {
    width: 150px;
}

button.ant-btn > .anticon + span, .ant-btn > span + .anticon {
    margin-left:4px;
}
div.ant-upload-list-item {
    margin-top:0px;
    &:hover {
       background: none;
    }
   
}

div.ant-picker-month-panel {
    & div.ant-picker-header {
        display: none;
    }
    & td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
        & div.ant-picker-cell-inner{
            width: 80px;
        }
       
    }
}

.view-report-form, .template-report-form {
    .ant-upload-list-item-card-actions {
        .ant-btn {
            width: auto;
            margin-left: 4px;
        }
    }
    .ant-upload-list-item-name {
        padding: 0px 0px 0px 8px !important;
    }
}

.view-report-form {
    div.ant-upload-list-item-info {
        width: 180px;
    }
}

.additional-file-table {
    .ant-table-cell {
        padding: 2px !important;
    }
    .ant-table-thead {
        .ant-table-cell {
            font-weight: 500;
            background-color: @pure-white !important;
            color: #00000066;
            &:not(.last-column) {
                border-right: 3px solid @pure-white !important;
            }
        }
    }
}