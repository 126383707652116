body div.ant-modal-content {
	border-radius: 12px;
	& div.ant-modal-header {
		padding: 14px;
		border-top-right-radius: 12px;
		border-top-left-radius: 12px;
		border-bottom: none;
		.ant-modal-title {
			font-size: 18px;
			font-weight: 800;
			color: @pure-black;
		}
	}
	& div.ant-modal-body {
		padding: 0px 14px 14px 14px;
		& .ant-input, .ant-picker, .ant-select-selector,.ant-input-number {
			border-radius: 4px;
		}
		& .ant-input-group {
			& > input.ant-input {
				border-radius: 0;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;				
			}
			& > span.ant-input-group-addon {
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				color:@default-black,
				
			}
		}
		span.anticon.anticon-minus-circle, span.anticon.anticon-paper-clip {
			color: @main-yellow;
		}
		& .ant-btn-dashed, .ant-radio-inner {
			border-color: @disable-yellow;
		}
		& .ant-input-number-handler-wrap {
			border: 0px;
		}
		& .ant-input-textarea-show-count::after {
			color: @pure-black;
		}
	}
	.ant-form-item-label > label {
		font-weight: 500;
	}
	.ant-form-item {
		margin-bottom: 14px;
	}
	.ant-modal-close-x {
		width: 50px;
		height: 50px;
	}
}