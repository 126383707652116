.search-form, .search-form-component {
    border-radius: 8px !important;
    .ant-card-head {
        min-height: 46px;
        padding: 0px 10px;
        border-bottom: none;
    }
    .ant-card-head-title {
        padding: 0px;
        color: @black;
        font-size: 14px;
        font-weight: 800;
     }
     .ant-card-extra {
        padding: 10px 0px 0px;
     }
}

.search-form-component {
    .ant-input-search-button {
        margin-bottom: 2px;
    }
    .ant-input-group {
        .ant-input {
            height: 22px;
            &:focus-within {
                height: 22px;
                border-bottom: none;
            }
        }
    }
}