.ant-input-affix-wrapper, .ant-select, .ant-input-number-input-wrap {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 2px !important;
    &:focus-within {
        border-bottom: 2px solid @main-yellow;
		margin-bottom: 0px !important;
    }
}

.ant-picker {
    padding-bottom: 2px !important;
    input {
        padding-bottom: 2px !important;
    }
    &:focus-within {
        border-bottom: 2px solid @main-yellow;
    }
}

.ant-input-group, .ant-form-item-control-input {
    .ant-input {
        height: 34px;
        &:focus-within {
            height: 34px;
            border-bottom: 2px solid @main-yellow;
        }
    }
}

.ant-modal-content {
    .ant-picker {
        height: 34px;
        &:focus-within {
            height: 34px;
            border-bottom: 2px solid @main-yellow;
        }
    }
}